import React from "react";
import { GameTypesList } from "../../services/HelperMethodsService";

interface CharacterImageProps {
    gameType: string;
}

export default function (props: CharacterImageProps) {
    let img = "";
    switch (props.gameType) {
        case GameTypesList().n80s: {
            img = "/assets/images/characters/80s.png";
            break;
        }
        case GameTypesList().n00s: {
            img = "/assets/images/characters/00s.png";
            break;
        }
        case GameTypesList().n90s: {
            img = "/assets/images/characters/90s.png";
            break;
        }
        case GameTypesList().nHollywood: {
            img = "/assets/images/characters/hollywood.png";
            break;
        }
        case GameTypesList().nMusic: {
            img = "/assets/images/characters/music.png";
            break;
        }
    }

    return (
        <div className={"character"}>
            <img className={"character-image"} src={img} />
        </div>
    );
}
