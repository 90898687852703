import React from "react";
import { inject, observer } from "mobx-react";

import "./ConnectionMessage.scss";

// @inject("SocketState")
// @inject("GameState")
// @observer
export default class ConnectionMessage extends React.Component<any> {
  state = {
    SocketState: null as any,
    hash: "",
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    this.setState(this.props);
    this.setState({ hash: window.location.hash });
  }
  componentDidUpdate() {}

  switchMessage() {
    if (this.state.SocketState && this.state.SocketState.connection.type == 4) {
      return (
        <div className="notice-message-wrapper">
          <div>
            <div className="">
              <i className="fas fa-exclamation-triangle" />
            </div>
            <div>{this.state.SocketState.connection.message}</div>
          </div>
        </div>
      );
    }

    return <></>;

    if (this.state.SocketState) {
      if (
        this.state.SocketState.connection.type == 1 &&
        this.state.hash != "#/game"
      ) {
        return (
          <div className="connection-message-wrapper">
            <div className="vertical-center">
              <i className="fas fa-spin fa-circle-notch" />
            </div>
          </div>
        );
      }

      if (this.state.SocketState.connection.type == 3) {
        return (
          <div className="connection-message-wrapper">
            <div>
              <div className="">
                <i className="fas fa-exclamation-triangle" />
              </div>
              <div>{this.state.SocketState.connection.message}</div>
            </div>
          </div>
        );
      }
    }

    // remove overflow class from body
    let body = document.getElementsByTagName("body")[0];
    body.classList.remove("no-overflow");

    return <div />;
  }

  render() {
    return this.switchMessage();
  }
}
