import React from "react";

import "./BackgroundColor.scss";

interface BackgroundProps {
    children: any,
    gameType: string,
    showIcons?: boolean,
}

export default function (props: BackgroundProps) {
    let icons = <></>;
    if (props.showIcons) {
        icons = <div className={"icons"}/>;
    }
    return (
        <div className={"background-color n" + props.gameType}>
            {icons}
            {props.children}
        </div>
    )
}