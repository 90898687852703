import React from "react";

import "./CurveWhite.scss";

export default function (props){
    return (
        <div className={"curve-white-top"}>
            {props.children}
        </div>
    )
}