import React, { useEffect, useRef, useState } from "react";

import "./theme_intro_animations/80s/80s.css";
import "./theme_intro_animations/00s/00s.css";
import "./theme_intro_animations/90s/90s.css";
import "./theme_intro_animations/hollywood/hollywood.css";
import "./theme_intro_animations/music/music.css";

import "./IntroAnimation.scss";
import { GameTypesList } from "../../services/HelperMethodsService";

declare var window: any;

interface IntoAnimationProps {
    gameType: string;
}

export default function (props: IntoAnimationProps) {
    useEffect(() => {
        switch (props.gameType) {
            case GameTypesList().n80s: {
                window.animateIntro("n80s_", 1, 12);
                break;
            }
            case GameTypesList().n00s: {
                window.animateIntro("n00s_", 1, 13);
                break;
            }
            case GameTypesList().n90s: {
                window.animateIntro("n90s_", 1, 12);
                break;
            }
            case GameTypesList().nHollywood: {
                window.animateIntro("hollywood_", 1, 11);
                break;
            }
            case GameTypesList().nMusic: {
                window.animateIntro("music_", 1, 12);
                break;
            }
        }
    }, []);

    return (
        <div className={"intro-animation-wrap"}>
            <div className={"quiz-wood into_anim n" + props.gameType} />
        </div>
    );
}
