import React, { useState } from "react";

export default function ConnectionInfo(props: { connectionId }) {
  return (
    <div className="connection-id">
      <div className="badge">UID:</div>
      {props.connectionId}
    </div>
  );
}
